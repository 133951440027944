import React from 'react';
import { graphql } from 'gatsby';

import { HTMLContent } from '../components/content';
import Header from '../components/header';
import InfoList from '../components/infoList';

const Info = ({ pageContext, data, location }) => {
  const { current: info, other: more } = data;

  return (
    <article className="page info">
      <Header
        path={location.pathname}
        title={info.frontmatter.title}
        back="/"
        intro={info.frontmatter.intro}
      />

      <HTMLContent content={info.html} />

      {more.edges.length ? (
        <>
          <h2>Máis info</h2>
          <InfoList info={more.edges} className="simple" />{' '}
        </>
      ) : null}
    </article>
  );
};

export default Info;

export const query = graphql`
  query($slug: String!) {
    current: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        intro
      }
      html
    }
    other: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/info/" }
        fields: { slug: { ne: $slug } }
        frontmatter: { hidden: { eq: false } }
      }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            intro
          }
        }
      }
    }
  }
`;
