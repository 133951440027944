import React, { Component } from 'react';
import { Link } from 'gatsby';
import Menu from './menu';

import Back from '../img/Back.svg';
import Burger from '../img/Burger.svg';
import Close from '../img/Close.svg';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    };
  }
  render() {
    const { back } = this.props;
    return (
      <nav className="navigation">
        <Link to={back || '/'} className="back">
          <img src={Back} alt="Volver" />
          <span>Volver</span>
        </Link>
        <button
          className="show-menu"
          onClick={() => this.setState({ showMenu: !this.state.showMenu })}
        >
          <img src={Burger} alt="Ver menú" />
          <span>Ver menú</span>
        </button>

        {this.state.showMenu ? (
          <div className="menu-overlay">
            <Menu showFrontpage={true} className="overlay" />
            <button
              onClick={() => this.setState({ showMenu: false })}
              className="close"
            >
              <img src={Close} alt="Cerrar" />

              <span>Cerrar</span>
            </button>
          </div>
        ) : null}
      </nav>
    );
  }
}

export default Navigation;
