import React from 'react';

import Navigation from './navigation';
import SEO from './SEO';

const Header = ({ title, back, intro, path }) => {
  return (
    <header>
      <Navigation back={back} />
      <h1>{title}</h1>
      {intro ? <p className="intro inner">{intro}</p> : null}

      <SEO title={title} description={intro} path={path} />
    </header>
  );
};

export default Header;
